import './Bio.css';

function Bio() {
  return (
    <div className="Bio">
      <p>
        I am a technical cybersecurity advisor. My mission is to apply my expertise to secure the software on which our society and its enterprises depend.
      </p>
      <p>
        I work with Cisco as a security architect.
        I graduated <i>summa cum laude</i> with a B.S. in Computer Science from East Carolina University, and I have a M.S. in Information Security Engineering from the SANS Technology Institute.
        I am a cybersecurity generalist, but I specialize in its intersections with DevOps, cloud computing, and machine learning.
      </p>
      <p>
        A subset of my professional accomplishments are listed below:
      </p>
      <h4>
        <ul>
          <li>
            Leveraged machine learning to empower analysts at LexisNexis to classify reported phishing emails up to 100x faster than was possible manually.
          </li>
          <li>
            Developed a microservice app to transfer files to highly secure Cloud environments at Cisco using public key cryptography and antivirus scanning.
          </li>
          <li>
            Designed and implemented Detection-as-Code at Cisco Meraki to centrally manage custom correlation rules across multiple isolated SIEM stacks.
          </li>
        </ul>
      </h4>
    </div>
  );
}

export default Bio;
